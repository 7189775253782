
form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /*max-width: 600px;*/
    /*margin: 0px auto;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.form-body {

}

.button-div {
    justify-content: center;
}

form > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.element {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

    select {
        width: 100%;
    }
}

.checkbox-list {
    display: flex;
    flex-direction: row;
}

.checkbox {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

select,
input[type="checkbox"] {
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/*button {*/
/*    background-color: #007bff;*/
/*    color: white;*/
/*    border: none;*/
/*    padding: 15px 20px;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*    font-size: 1em;*/
/*    align-self: center;*/
/*    width: 200px;*/
/*    transition: background-color 0.3s ease;*/
/*}*/

/*button:hover {*/
/*    background-color: #0056b3;*/
/*}*/
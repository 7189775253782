/* CalendarView.css */
.calendar {
    position: relative;
}

.rbc-toolbar button {
    cursor: pointer;
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    margin: 0 5px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    position: relative; /* Ensure buttons are positioned correctly */
    z-index: 1; /* Make sure buttons are on top */
}

.rbc-toolbar button:hover {
    background-color: #d0d0d0;
}

.rbc-toolbar button:focus {
    outline: none;
}

.rbc-toolbar button:active {
    background-color: #b0b0b0;
}

.rbc-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative; /* Ensure toolbar is positioned correctly */
    z-index: 1; /* Make sure toolbar is on top */
}

.rbc-toolbar-label {
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
}

.rbc-btn-group {
    display: flex;
    align-items: center;
}

.rbc-btn-group button {
    flex-grow: 1;
}

.rbc-btn-group button span {
    pointer-events: none; /* Ensure text does not block button clicks */
}

.modal {
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    /*width: auto;*/
}

.overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.activity-model-body {
    display: flex;
    flex-direction: column;
    box-shadow: none;
}

.modal-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.form-label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
}

.form-group {
    /*margin-bottom: 20px;*/
}

.time-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.time-select {
    flex: 1;
}

.time-select:first-child {
    margin-right: 10px;
}

.css-2b097c-container {
    border: none; /* remove the default border */
    box-shadow: none; /* remove the box shadow */
    /*min-width: 120px; !* Define a largura mínima *!*/
}

.css-1uccc91-singleValue {
    color: #000; /* Define a cor do texto */
}

.css-1okebmr-indicatorSeparator {
    display: none; /* Remove a barra de separação do indicador */
}

.css-1pahdxg-control {
    max-height: 40px; /* Limita a altura do campo de seleção */
}

.css-1hb7zxy-IndicatorsContainer {
    height: 40px; /* Define a altura dos indicadores */
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    /*margin-top: 20px;*/
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.submit-button {
    background-color: #28a745;
    color: white;
    max-height: 50px;
}

.submit-button:hover {
    background-color: #218838;
}

.close-button {
    background-color: #dc3545;
    color: white;
}

.close-button:hover {
    background-color: #c82333;
}

hr {
    border: none;
    border-top: 1px solid #e9ecef;
    margin: 20px 0;
}

.invalid .css-1hwfws3 {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}

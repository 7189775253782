.day-column {
    border: 1px solid #ccc;
    padding: 10px;
    width: 14%;  /* Aumenta a largura da coluna */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.day-column h3 {
    margin-top: 0;
}

.day-column ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.day-column li {
    background-color: #fff;
    margin: 5px 0;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
    text-align: center;
}

.add-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 20px;
}

.add-button:hover {
    background-color: #218838;
}

.activity {
    padding: 5px;
    margin: 5px 0;
    cursor: pointer;
    background-color: #2cfb6c;
    border-radius: 3px;
}

.activity:hover {
    background-color: #e0e0e0;
}
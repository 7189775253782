.container {
  display: flex;
  justify-content: space-around;
}

.form-container {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  padding: 10px;
}

.bar-container {
  display: flex;
}

.right-bar {

}
.options-bar {
  display: flex;
}
.radar-chart {
  width: 350px;
}
/* CustomEvent.css */
.custom-event {
    padding: 5px;
    background-color: #2a6ebb;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.custom-event strong {
    font-size: 14px;
    margin-bottom: 2px;
}

.custom-event p {
    margin: 2px 0;
}
